import { useLocalization } from 'hooks';
import { ParticipationView, Role } from 'views/Registration';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBaseLanguage, useOrganization } from '@guider-global/sanity-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import { Button, Stack } from '@guider-global/ui';
import { ProgressContainer } from 'containers';
import { useProfiles } from '@guider-global/front-end-hooks';

export function ParticipationPage() {
  const [role, setRole] = useState<Role>(undefined);
  const { pathname } = useLocation();

  const isEditingProfile = pathname?.includes('profile');

  const navigate = useNavigate();

  // Organization

  const organizationSlug = getSubDomain();
  const { organization } = useOrganization({ organizationSlug });

  const openMatchingText =
    organization?.open_matching?.program_type?.program_type_text?.common
      ?.open_matching;

  // Base Language
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const participationConfig =
    baseLanguage?.registration?.open_matching?.participation;
  const continueButtonLabel =
    baseLanguage?.globals?.common?.continue_button_label ?? 'Continue';

  // Profiles

  const { profile, reqProfiles, isMutatingProfiles, profilesRevalidate } =
    useProfiles({
      params: { integrationsEnabled: true },
    });

  useEffect(() => {
    if (!profile?.roles || profile?.roles?.length === 0) return;

    if (
      profile?.roles?.includes('trainee') &&
      profile?.roles?.includes('guide')
    ) {
      setRole('both');
    } else {
      setRole(profile?.roles?.at(0));
    }
  }, [profile]);

  async function handleParticipation() {
    if (!profile) {
      return;
    }

    const roles: ('guide' | 'trainee')[] = [];

    switch (role) {
      case 'both':
        roles.push('guide', 'trainee');
        break;
      case 'trainee':
        roles.push('trainee');
        break;
      case 'guide':
        roles.push('guide');
        break;
      default:
        break;
    }

    try {
      await reqProfiles({
        method: 'PATCH',
        url: `/profiles/${profile.id}`,
        data: {
          roles,
        },
      });
      profilesRevalidate();

      navigate('../goals');
    } catch (e) {
      console.log(e);
    }
  }

  if (!profile)
    return (
      <ProgressContainer
        onBackButtonClick={isEditingProfile ? () => navigate(-1) : undefined}
      >
        <></>
      </ProgressContainer>
    );

  return (
    <ProgressContainer
      onBackButtonClick={isEditingProfile ? () => navigate(-1) : undefined}
    >
      <Stack
        direction={'column'}
        justifyContent={'flex-start'}
        width={{ xs: '90%', md: '480px' }}
        pt={5}
      >
        <ParticipationView
          title={participationConfig?.title}
          alertLabel={participationConfig?.alert_label}
          guideText={openMatchingText?.guide_participation}
          traineeText={openMatchingText?.trainee_participation}
          handleRoleSelection={(role: Role) => {
            setRole(role);
          }}
          currentRole={role}
        />
        <Button
          variant="contained"
          label={continueButtonLabel}
          color="info"
          onClick={handleParticipation}
          loading={isMutatingProfiles}
          disabled={!role}
          data-cy="register-participation-continue-button"
        />
      </Stack>
    </ProgressContainer>
  );
}
