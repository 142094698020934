import { MaintenanceWrapper } from 'components/MaintenanceWrapper';
import { IntercomProvider } from 'react-use-intercom';

const { REACT_APP_INTERCOM_APP_ID } = process.env;

export function MaintenancePage() {
  return (
    <IntercomProvider
      appId={REACT_APP_INTERCOM_APP_ID ? REACT_APP_INTERCOM_APP_ID : ''}
    >
      <MaintenanceWrapper isIntercom={Boolean(REACT_APP_INTERCOM_APP_ID)} />
    </IntercomProvider>
  );
}
