import { ProgressContainer } from 'containers';
import { GroupMembershipForm } from 'forms';
import { useNavigate, useParams } from 'react-router-dom';

export function GroupMembershipPage() {
  const navigate = useNavigate();

  const { role } = useParams<{
    role: 'trainee' | 'guide';
  }>();

  return (
    <ProgressContainer
      onBackButtonClick={() => navigate(-1)}
      percentage={role === 'guide' ? 33 : 50}
    >
      <GroupMembershipForm />
    </ProgressContainer>
  );
}
