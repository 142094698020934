import { useProfiles } from '@guider-global/front-end-hooks';
import { ProgressContainer } from 'containers';
import { OnboardingForm } from 'forms/OnboardingForm';

export function CreateProfilePage() {
  const { profile } = useProfiles({ params: { integrationsEnabled: true } });

  return (
    <ProgressContainer>
      <OnboardingForm profile={profile} />
    </ProgressContainer>
  );
}
