// external
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

// store
import { RootState } from 'store';
import { showAppAlert } from 'store/slices/appSlice';

// hooks
import { useNavigate } from 'react-router-dom';

const mapState = (state: RootState) => {
  return {
    isAppLoading: state.app.loading,
    baseLanguage: state.sanityBaseLanguage.baseLanguage,
  };
};

const mapDispatch = { showAppAlert };

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const LogoutPageComponent: React.FunctionComponent<PropsFromRedux> = ({
  baseLanguage,
  showAppAlert,
}) => {
  const { navigation } = baseLanguage;
  const navigate = useNavigate();

  useEffect(() => {
    showAppAlert({
      severity: 'success',
      message: navigation?.logout_successful_alert_message,
      timeout: 10000,
    });

    navigate('/');
  }, [navigate, navigation, showAppAlert]);

  return <></>;
};

export const LogoutPage = connector(LogoutPageComponent);
