/* eslint-disable camelcase */
//external
import { FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//components
import LegalNotices from 'components/legalNotices';

//types
import { INavbarLink } from 'containers';

//hooks
import { useAppDispatch } from 'store/hooks';
import { setNavbarUnauthenticatedActions } from 'store/slices/appSlice';
import { getSubDomain } from '@guider-global/front-end-utils';
import { useLocalization } from 'hooks';
import { useBaseLanguage } from '@guider-global/sanity-hooks';

const LegalPageCookies: FC = () => {
  // Route
  const navigate = useNavigate();
  //Redux
  const dispatch = useAppDispatch();

  //Auth0
  const handleLogin = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  const handleRegister = useCallback(
    () => navigate('/register/account'),
    [navigate],
  );

  const organizationSlug = getSubDomain();

  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const legal_notices = baseLanguage?.legal_notices;
  const baseLanguageNavigation = baseLanguage?.navigation;

  useEffect(() => {
    if (!baseLanguageNavigation) return;

    const organizationUnauthenticatedActions: INavbarLink[] = [
      {
        label: baseLanguageNavigation?.login_button_label,
        action: handleLogin,
        type: 'button',
        variant: 'outlined',
        dataCy: 'navbar-login-button',
      },
      {
        label: baseLanguageNavigation?.register_button_label,
        action: handleRegister,
        type: 'button',
        variant: 'contained',
        color: 'info',
        dataCy: 'navbar-unauthenticated-register-button',
      },
    ];

    dispatch(
      setNavbarUnauthenticatedActions(organizationUnauthenticatedActions),
    );
  }, [baseLanguageNavigation, dispatch, handleLogin, handleRegister]);

  const cookiesPolicyContent =
    legal_notices?.cookie_policy?.cookie_policy_content ?? [];
  const cookiesPolicyContentLabel =
    legal_notices?.cookie_policy?.cookie_policy_label;

  return (
    <>
      <LegalNotices
        title={cookiesPolicyContentLabel}
        content={cookiesPolicyContent}
      />
    </>
  );
};

export default LegalPageCookies;
