import { useProfiles } from '@guider-global/front-end-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage, useOrganization } from '@guider-global/sanity-hooks';
import { Button, Stack, TextField, TextStack } from '@guider-global/ui';
import { ProgressContainer } from 'containers';
import { useLocalization } from 'hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export function BioPage() {
  const [bio, setBio] = useState('');

  const navigate = useNavigate();

  // Organization
  const organizationSlug = getSubDomain();
  const { organization } = useOrganization({
    organizationSlug,
  });

  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const skipLabel = baseLanguage?.globals?.common?.skip_button_label ?? 'Skip';

  const allowOpenMatching =
    organization?.open_matching?.enable_open_matching ?? false;

  const { profile, reqProfiles, isMutatingProfiles, profilesRevalidate } =
    useProfiles({
      params: { integrationsEnabled: true },
    });

  useEffect(() => {
    if (profile?.bio) {
      setBio(profile.bio);
    }
  }, [profile?.bio]);

  async function handleBioSubmit() {
    if (!profile) return;

    if (profile?.roles?.includes('trainee') && allowOpenMatching) {
      await reqProfiles({
        method: 'PATCH',
        url: `/profiles/${profile.id}`,
        data: {
          bio,
        },
      });
      return navigate('../matches/guide');
    }

    const result = await reqProfiles({
      method: 'PATCH',
      url: `/profiles/${profile.id}`,
      data: {
        bio,
        isOnboarded: true,
      },
    });
    profilesRevalidate();

    if (result.status === 'success') {
      navigate('/dashboard');
    }
  }

  async function handleSkip() {
    if (!profile) return;
    handleBioSubmit();
  }
  if (!profile)
    return (
      <ProgressContainer
        onBackButtonClick={allowOpenMatching ? () => navigate(-1) : undefined}
      >
        <></>
      </ProgressContainer>
    );

  return (
    <ProgressContainer
      onBackButtonClick={allowOpenMatching ? () => navigate(-1) : undefined}
      endAdornment={
        <Button variant="text" onClick={handleSkip} label={skipLabel} />
      }
    >
      <Stack
        direction={'column'}
        justifyContent={'flex-start'}
        width={{ xs: '90%', md: '480px' }}
        pt={5}
      >
        <TextStack
          spacing={1.5}
          size="large"
          heading={{
            text: baseLanguage?.registration?.open_matching?.bio?.title,
            variant: 'h2',
            lineHeight: '115%',
          }}
          subtitles={[
            {
              text: baseLanguage?.registration?.open_matching?.bio?.description,
              color: 'text.secondary',
              variant: 'subtitle1',
            },
          ]}
        />
        <TextField
          rows={10}
          placeholder={
            baseLanguage?.registration?.open_matching?.bio?.placeholder
          }
          onChange={(e) => setBio(e.target.value)}
          value={bio}
          InputProps={{ 'data-cy': 'register-bio-textarea' } as any}
        />
        <Button
          variant="contained"
          label={baseLanguage?.globals?.common?.continue_button_label}
          color="info"
          onClick={handleBioSubmit}
          loading={isMutatingProfiles}
          data-cy="register-bio-continue-button"
        />
      </Stack>
    </ProgressContainer>
  );
}
