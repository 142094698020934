import { getSubDomain } from '@guider-global/front-end-utils';
import { Button, TextField } from '@guider-global/ui';
import { Box, Typography } from '@mui/material';
import { PageWrapper } from 'components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const ConfigurePage = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState<string>('');
  const handleChange = (e: { target: { value: string } }) => {
    setValue(e.target.value);
  };

  const setSubdomain = () => {
    navigate(`?org=${value}`);
  };

  const organizationSlug = getSubDomain();

  useEffect(() => {
    organizationSlug !== 'teams' && navigate('/');
  }, [organizationSlug, navigate]);

  return (
    <PageWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          src="https://cdn.sanity.io/images/wrhtm9cw/production/e7a9eb64dc769fe9b9bfd8b3b0d65b415c972a13-512x512.png"
          sx={{ width: '300px' }}
        />
        <Typography sx={{ textAlign: 'center' }} variant="h3">
          We need to know who you are!
        </Typography>
        <Typography sx={{ textAlign: 'center', my: 2 }} variant="subtitle1">
          Please provide your organization name to proceed,
          <br />
          it's the first part of your Guider URL, for example,
          <br />
          in{' '}
          <Typography
            sx={{
              fontFamily: `"Lucida Console", "Courier New", 'monospace'`,
              display: 'inline',
            }}
          >
            microsoft.guider.app
          </Typography>{' '}
          the organization name is{' '}
          <Typography
            sx={{
              fontFamily: `"Lucida Console", "Courier New", 'monospace'`,
              display: 'inline',
            }}
          >
            microsoft
          </Typography>
        </Typography>
        <TextField onChange={handleChange} sx={{ mb: 2 }} />
        <Button color="secondary" onClick={setSubdomain} sx={{}}>
          Continue
        </Button>

        <Typography sx={{ textAlign: 'center', mt: 10 }} variant="subtitle2">
          Don’t have a mentoring program set up at your company yet?
          <br />
          Don’t worry, we can help, just email{' '}
          <Typography component="a" href="mailto:hello@guider-ai.com">
            hello@guider-ai.com
          </Typography>{' '}
          with “get mentoring”.
        </Typography>
      </Box>
    </PageWrapper>
  );
};
