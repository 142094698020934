import React from 'react';
import { NotFound } from 'components/NotFound';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GenericError } from 'components';

interface IErrorPageProps {
  error?: 401 | 402 | 403 | 404 | 500;
  message?: string;
}

export const ErrorPage: React.FC<IErrorPageProps> = ({
  error = 404,
  message,
}) => {
  // Route
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const urlErrorParam = searchParams.get('error');
  const urlErrorMessageParam = searchParams.get('message');

  const urlErrorCode = urlErrorParam && parseInt(urlErrorParam);

  const errorCode = urlErrorCode ?? error;
  const errorMessage = urlErrorMessageParam ?? message;

  const handleRedirect = () => {
    navigate('/');
  };

  if (errorCode === 404) {
    return <NotFound />;
  } else {
    return (
      <GenericError
        code={urlErrorParam ?? 'unexpectedError'}
        message={errorMessage ?? 'Sorry, an unknown error occurred '}
        visible
        overlay={false}
        action={{ onclick: handleRedirect, label: 'Go to Home' }}
      />
    );
  }
};

export default ErrorPage;
