import { useBaseLanguage, useOrganization } from '@guider-global/sanity-hooks';
import {
  Button,
  MultiSelectAreaInputValue,
  RoleChip,
  SideBarIndicator,
  SkillCategoriesView,
  TextStack,
} from '@guider-global/ui';
import { Stack } from '@guider-global/ui/lib/components/Layout/Stack';
import { useLocalization } from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { ProgressContainer } from 'containers';
import { getSubDomain } from '@guider-global/front-end-utils';
import { SubmitHandler, useForm } from 'react-hook-form';
import guideAvatar from 'assets/guideAvatar.svg';
import traineeAvatar from 'assets/traineeAvatar.svg';
import { useEffect } from 'react';
import { useProfiles } from '@guider-global/front-end-hooks';
import { IProfile, ISkill } from '@guider-global/shared-types';
import { interpolate } from 'functions';

type SkillFormValues = {
  [key: string]: MultiSelectAreaInputValue;
};

export function ProfileSkillsPage() {
  const navigate = useNavigate();

  const { role, type } = useParams<{
    role: 'guide' | 'trainee';
    type: 'hard' | 'soft';
  }>();

  const skillType = type === 'soft' ? 'soft_skills' : 'hard_skills';

  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const { organization } = useOrganization({ organizationSlug });

  const { reqProfiles, profiles, isMutatingProfiles, profilesRevalidate } =
    useProfiles({});

  const profile = profiles?.at(0);

  const allowBioQuestion = organization?.personal_details?.enable_bio ?? true;

  const validationSettings =
    type === 'hard'
      ? organization?.skills?.config?.hard_skills_validation
      : organization?.skills?.config?.soft_skills_validation;

  const min = validationSettings?.minimum_selections;
  const max = validationSettings?.maximum_selections;
  const maxSkillsErrorMessage =
    baseLanguage?.globals?.errors?.maximum_selections_exceeded;

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<SkillFormValues>({
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<SkillFormValues> = async (
    data: SkillFormValues,
  ) => {
    if (!type || !role) return;

    const skills = data?.[skillType]?.value;

    let oppositeSkills: any[] = [];

    if (role === 'guide') {
      oppositeSkills = (profile?.skills as Partial<ISkill>[])
        ?.filter(
          (skill) =>
            skill &&
            skill.offered &&
            (type === 'soft' ? skill?.type === 'hard' : skill?.type === 'soft'),
        )
        ?.map((skill) => skill.fieldSlug)
        .filter(Boolean);
    } else {
      oppositeSkills = (profile?.skills as Partial<ISkill>[])
        ?.filter(
          (skill) =>
            skill &&
            skill.sought &&
            (type === 'soft' ? skill?.type === 'hard' : skill?.type === 'soft'),
        )
        ?.map((skill) => skill.fieldSlug)
        .filter(Boolean);
    }

    const profileData: Partial<IProfile> & {
      hardSkillSlugs?: string[];
      softSkillSlugs?: string[];
      role: 'trainee' | 'guide';
    } = {
      ...(type === 'soft' && {
        softSkillSlugs: skills,
        hardSkillSlugs: oppositeSkills as string[],
      }),
      ...(type === 'hard' && {
        hardSkillSlugs: skills,
        softSkillSlugs: oppositeSkills as string[],
      }),
      role,
    };

    await reqProfiles({
      method: 'PATCH',
      url: `/profiles/${profile?.id}`,
      data: profileData,
    });
    profilesRevalidate();

    if (type === 'soft') {
      navigate(`../skills/${role}/hard`);
    } else {
      if (role === 'guide' && profile?.roles?.includes('trainee')) {
        navigate('../skills/trainee/soft');
      } else {
        if (allowBioQuestion) {
          return navigate('../bio');
        }

        if (profile?.roles?.includes('trainee')) {
          return navigate('../matches/guide');
        }

        const result = await reqProfiles({
          method: 'PATCH',
          url: `/profiles/${profile?.id}`,
          data: {
            isOnboarded: true,
          },
        });
        profilesRevalidate();

        if (result.status === 'success') {
          return navigate('/dashboard');
        }
      }
    }
  };

  useEffect(() => {
    if (!type || !profile) return;

    const skillOptions = organization?.skills?.[skillType]?.flatMap(
      (skill) => skill.skills,
    );

    const profileSkills = (profile?.skills as Partial<ISkill>[])
      ?.filter((skill) => (role === 'guide' ? skill?.offered : skill?.sought))
      .map((skill) => skill.fieldSlug);

    const value =
      skillOptions
        ?.filter((option) => profileSkills?.includes(option?.slug))
        ?.map((skill) => skill.slug) ?? [];

    return reset({
      [skillType]: {
        fieldType: 'multi-select',
        name: skillType,
        value,
      },
    });
  }, [profile, reset, skillType, type, role, organization?.skills]);

  if (!profile || !role || !type)
    return (
      <ProgressContainer onBackButtonClick={() => navigate(-1)}>
        <></>
      </ProgressContainer>
    );

  return (
    <ProgressContainer onBackButtonClick={() => navigate(-1)}>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        width={{ xs: '90%', md: '680px' }}
        justifyContent={'space-between'}
        alignItems={'flex-start'}
        gap={{ xs: 1, md: 5 }}
      >
        <SideBarIndicator
          items={[
            {
              text: baseLanguage?.globals?.skills?.soft_skills_label,
            },
            {
              text: baseLanguage?.globals?.skills?.hard_skills_label,
              disabled: skillType === 'soft_skills',
            },
          ]}
        />
        <Stack
          component={'form'}
          onSubmit={handleSubmit(onSubmit)}
          direction={'column'}
          gap={2}
          spacing={0}
          width={{ xs: '100%', md: '480px' }}
        >
          <RoleChip
            label={interpolate(
              baseLanguage?.registration?.open_matching?.common?.preheader,
              {
                roleSingular:
                  organization?.open_matching?.program_type?.program_type_text
                    ?.common?.[role]?.singular,
              },
            )}
            role={role}
            avatarSrc={role === 'guide' ? guideAvatar : traineeAvatar}
          />
          <TextStack
            spacing={1}
            size="xs"
            heading={{
              text: organization?.skills?.text?.[role]?.question_title
                ? organization?.skills?.text?.[role]?.question_title
                : baseLanguage?.globals?.skills?.[
                    `${role}_skills_question_title`
                  ],
              variant: 'h2',
              lineHeight: '115%',
            }}
            subtitles={[
              {
                text: organization?.skills?.text?.[role]?.question_description
                  ? organization?.skills?.text?.[role]?.question_description
                  : baseLanguage?.globals?.skills?.[
                      `${role}_skills_question_description`
                    ],
                color: 'text.secondary',
                variant: 'subtitle1',
              },
              {
                text:
                  type === 'hard'
                    ? organization?.skills?.text?.hard_skills_custom_description
                    : organization?.skills?.text
                        ?.soft_skills_custom_description,
                color: 'text.secondary',
                variant: 'subtitle1',
              },
            ]}
          />
          <SkillCategoriesView
            categories={organization?.skills?.[skillType] ?? []}
            control={control}
            placeholder="Search for skills"
            name={skillType}
            min={min}
            max={max}
            maxErrorMessage={maxSkillsErrorMessage}
          />
          <Button
            variant="contained"
            label={baseLanguage?.globals?.common?.continue_button_label}
            color="info"
            type="submit"
            data-cy="register-skills-continue-button"
            loading={isMutatingProfiles}
            disabled={!isValid}
          />
        </Stack>
      </Stack>
    </ProgressContainer>
  );
}
