import {
  DashboardResourcesContainer,
  DashboardWrapper,
  OpenMatchingProfileContainer,
  ProgramMembershipsContainer,
} from 'containers';
import { HeroSectionContainer } from 'containers/dashboard/HeroSectionContainer';

export const DashboardPage = () => {
  return (
    <DashboardWrapper>
      <HeroSectionContainer />
      <OpenMatchingProfileContainer />
      <ProgramMembershipsContainer />
      <DashboardResourcesContainer />
    </DashboardWrapper>
  );
};
